import i18n from "@/plugins/i18n";

export function WatchI18nForm(form = "form") {
  return {
    "$i18n.locale": function () {
      let form = this.$refs[form];
      if (!form) return;
      form.fields.forEach(item => {
        if (item.validateState === 'error') {
          form.validateField(item.prop)
        }
      })
    }
  }
}


export const NotEmptyValidator = (rule, value, callback) => {
  if (value) callback();
  else callback(new Error(i18n.t("tips.notEmpty")))
}