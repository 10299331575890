<template>
  <el-dialog ref="dialog" :visible.sync="visible" append-to-body class="dialog" @opened="handleOpened">
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item :label="$t('newadd.t.国家/地区')" prop="country">
        <el-select v-model="form.country" class="full" @change="handleSelect($event,'country')">
          <el-option v-for="(item,i) in source.country" :key="i" :value="item.ID" :label="$lang(item,'name')"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('newadd.t.省')" v-if="form.country " prop="province">
        <el-select v-model="form.province" class="full" @change="handleSelect($event,'province')">
          <el-option v-for="(item,i) in source.province" :key="i" :value="item.ID" :label="$lang(item,'name')"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('newadd.t.市')" v-if="form.country == 86 && form.province" prop="city">
        <el-select v-model="form.city" class="full" @change="handleSelect($event,'city')">
          <el-option v-for="(item,i) in source.city" :key="i" :value="item.ID" :label="$lang(item,'name')"/>
        </el-select>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {NotEmptyValidator, WatchI18nForm} from "@/utils/Validator";

export default {
  name: "AddressSelect",
  data() {
    return {
      visible: false,
      form: {
        country: null,
        province: null,
        city: null,
      },
      select: {},
      source: {
        country: null,
        province: null,
        city: null,
      },
    }
  },
  watch: {
    ...WatchI18nForm(),
    "form.country": function (val) {
      this.source.province = null;
      this.source.city = null;
      this.source.county = null;

      this.form.province = null;
      this.form.city = null;
      this.form.county = null;

      if (this.form.country)
        this.loadList("province", val)
    },
    "form.province": function (val) {
      this.source.city = null;
      this.source.county = null

      this.form.city = null;
      this.form.county = null;

      if (this.form.province)
        this.loadList("city", val)
    },
  },
  computed: {
    rules() {
      return {
        country:[{validator:NotEmptyValidator}],
        province:[{validator:NotEmptyValidator}],
        city:[{validator:NotEmptyValidator}],
      };
    }
  },
  mounted() {

  },
  methods: {
    getById(id, type) {
      let list = this.source[type];
      if (list) {
        for (const item of list) {
          if (item.ID == id) return item;
        }
      }
      return null
    },
    handleOpened() {
      if (this.source.city == null) {
        this.loadList("country")
      }
    },
    handleSelect(val, attr) {
      this.select[attr] = this.getById(val, attr);
    },
    loadList(type, parent = 0) {
      this.$req.get("/client/im/user/address/query", {parent}).then(res => {
        res.forEach(this.formatAddress)
        this.source[type] = res;
      })
    },
    formatAddress(item) {
      let {Add, Add_cn} = item;
      item.nameZhCn = Add_cn;
      item.nameEnUs = Add;
      delete item.Add;
      delete item.Add_cn;
      return item;
    },

    show() {
      this.visible = true;
    },
    async handleConfirm() {
      try{
        await this.$refs.form.validate();
        this.visible = false;
        this.$emit("confirm", this.select)
      }catch {}
    },

  },
}
</script>

<style scoped lang='scss'>
.dialog ::v-deep {
  .el-dialog {
    width: 350px;

    .el-select {
      width: 100%;
    }
  }
}
</style>